<template>
  <div class="flex flex-col pt-48 items-center justify-center">
    <small>404 ERROR</small>
    <h1>
      There’s no page here
    </h1>
    <h5 class="text-gray-500">
      Looks like you ended up here by accident?
    </h5>

    <div class="mt-10">
      <router-link :to="{ path: '/' }" class="btn btn-primary text-lg">
        Back to dashboard
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
